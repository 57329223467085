<template>
  <!-- 素材管理 -->
  <div class="scgl">
    <div class="scgl-row1">
      <span>
        <el-select
          v-model="model.scenarioId"
          style="width: 100px"
          size="mini"
           filterable
          placeholder="关联剧本"
        >
          <el-option label="全部剧本" :value="'0'"></el-option>
          <el-option
            v-for="(option, oi) in scenarioList"
            :key="oi"
            :label="option.scenarioName"
            :value="option.id"
          ></el-option>
        </el-select>
        <el-select
          v-model="model.isAudit"
          style="width: 100px; margin-left: 10px"
          size="mini"
          placeholder="审核状态"
        >
          <el-option
            v-for="(option, oi) in stateList"
            :key="oi"
            :label="option.name"
            :value="option.id"
          ></el-option>
        </el-select>
        <el-select
          v-model="model.pictureTypeId"
          style="width: 100px; margin-left: 10px"
          size="mini"
          placeholder="类别"
        >
          <el-option label="类型" :value="'0'"></el-option>
          <el-option
            v-for="(option, oi) in list4"
            :key="oi"
            :label="option.dictLabel"
            :value="option.dictValue"
          ></el-option>
        </el-select>
        <el-select
          v-model="model.pictureSexId"
          style="width: 100px; margin-left: 10px"
          size="mini"
          placeholder="性别"
        >
          <el-option label="性别" :value="'0'"></el-option>
          <el-option
            v-for="(option, oi) in list3"
            :key="oi"
            :label="option.dictLabel"
            :value="option.dictValue"
          ></el-option>
        </el-select>
        <el-select
          v-model="model.pictureAgeId"
          style="width: 100px; margin-left: 10px"
          size="mini"
          placeholder="年龄"
        >
          <el-option label="年龄" :value="'0'"></el-option>
          <el-option
            v-for="(option, oi) in list2"
            :key="oi"
            :label="option.dictLabel"
            :value="option.dictValue"
          ></el-option>
        </el-select>
        <el-select
          v-model="model.pictureStyleId"
          style="width: 100px; margin-left: 10px"
          size="mini"
          placeholder="时空"
        >
          <el-option label="时空" :value="'0'"></el-option>
          <el-option
            v-for="(option, oi) in list1"
            :key="oi"
            :label="option.dictLabel"
            :value="option.dictValue"
          ></el-option>
        </el-select>
        <!-- <el-select style="width: 100px; margin-left: 10px" size="mini" placeholder="上传时间">
        </el-select> -->
      </span>
      <span class="right">
        <el-input
          size="mini"
          style="width: 120px"
          v-model="model.content"
          placeholder="搜索关键词"
        ></el-input>
        <el-button size="mini" style="margin-left: 10px">批量管理</el-button>
      </span>
    </div>
    <div class="scgl-row2">
      <el-card class="scgl-row2-card">
        <div class="scgl-card-content">
          <div
            class="scgl-item"
            v-for="(item, i) in pictureList"
            :key="i"
            @mouseover="hoverItem(item, true)"
            @mouseleave="hoverItem(item, false)"
          >
            <div class="scgl-item-image">
              <!-- 底图 -->
              <img
                style="width: 100%; height: 100%; border-radius: 4px"
                :src="item.picturePath"
              />
              <!-- 底图遮罩-hover专用-修改图片 -->
              <div
                class="item-image-mask"
                v-if="item.mask"
                @click="updateImage(item, i)"
              >
                <span>
                  <i class="el-icon-edit-outline" style="font-size: 28px"></i>
                </span>
              </div>
              <!-- 左上角选择框 -->
              <div class="scgl-left">
                <!-- <el-checkbox></el-checkbox> -->
              </div>
              <!-- 右上角标签 -->
              <!-- <div class="right" :style="{background: returnState(item.status).color}">{{ returnState(item.status).name }}</div> -->
            </div>
            <div class="item-message">
              <div class="row1">
                {{ item.content || "未命名" }}
              </div>
              <div class="row2">
                <span>{{ pictureTypeName(item.pictureTypeId) }}</span>
                <span style="color: rgba(153, 153, 153, 1)">{{
                  timestampToDate(item.createTime)
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </el-card>
    </div>
    <div class="row3">
      <el-pagination
        :total="total"
        :current-page.sync="model.page"
        :page-size.sync="model.limit"
        layout="prev, pager, next"
      ></el-pagination>
    </div>

    <!-- 修改图片弹窗 -->
    <el-dialog center width="960px" :visible.sync="updateImageDialog">
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: space-around;
        "
      >
        <img
          v-if="pictureModel.picturePath"
          style="width: 198px; height: 148px"
          :src="pictureModel.picturePath"
        />
        <div style="font-size: 28px">替换成：</div>
        <el-upload
          class="avatar-uploader"
          :action="$config.VUE_APP_URL + $filesUrl[0]"
          :show-file-list="false"
          :on-success="uploadSuccess"
          :headers="{
            Authorization: `bearer ${accessToken}`,
          }"
          :data="{
            fileType: 1,
            pictureId: updateImageItem ? updateImageItem.id : '',
          }"
          :before-upload="beforeUpload"
        >
          <img
            v-if="afterUpdateImageURL"
            :src="afterUpdateImageURL"
            class="avatar"
          />
          <div v-else>
            <i class="el-icon-plus avatar-uploader-icon"></i>
            <div>图片要求：</div>
            <div>png,jpg,gif格式，2M以内大小。</div>
          </div>
        </el-upload>
      </div>
      <div class="flex-cloumn flex-as">
        <div
          style="
            display: flex;
            justify-content: center;
            margin-top: 20px;
            align-items: center;
          "
        >
          <el-tag size="small" style="width: 96px" effect="dark">名称</el-tag>
          <el-input
            v-model="pictureModel.content"
            placeholder="请输入素材名"
            size="mini"
            style="width: 322px; margin-left: 12px"
          ></el-input>
        </div>

        <div
          style="
            display: flex;
            justify-content: center;
            margin-top: 8px;
            align-items: center;
          "
        >
          <el-tag size="small" style="width: 96px" effect="dark"
            >关联剧本</el-tag
          >
          <el-select
            v-model="pictureModel.scenarioId"
            style="width: 322px; margin-left: 12px"
            size="mini"
            placeholder="剧本"
          >
            <el-option label="全部剧本" :value="'0'"></el-option>
            <el-option
              v-for="(option, oi) in scenarioList"
              :key="oi"
              :label="option.scenarioName"
              :value="option.id"
            ></el-option>
          </el-select>
        </div>
        <div style="display: flex; justify-content: center; margin-top: 8px">
          <el-select
            v-model="pictureModel.pictureTypeId"
            style="width: 100px"
            size="mini"
            placeholder="类别"
          >
            <el-option label="全部类别" :value="'0'"></el-option>
            <el-option
              v-for="(option, oi) in list4"
              :key="oi"
              :label="option.dictLabel"
              :value="option.dictValue"
            ></el-option>
          </el-select>

          <el-select
            style="width: 100px; margin-left: 10px"
            size="mini"
            placeholder="性别"
            v-model="pictureModel.pictureSexId"
          >
            <el-option label="全部" :value="'0'"></el-option>
            <el-option
              v-for="(option, oi) in list3"
              :key="oi"
              :label="option.dictLabel"
              :value="option.dictValue"
            ></el-option>
          </el-select>

          <el-select
            style="width: 100px; margin-left: 10px"
            size="mini"
            placeholder="年龄"
            v-model="pictureModel.pictureAgeId"
          >
            <el-option label="全部" :value="'0'"></el-option>
            <el-option
              v-for="(option, oi) in list2"
              :key="oi"
              :label="option.dictLabel"
              :value="option.dictValue"
            ></el-option>
          </el-select>
          <el-select
            style="width: 100px; margin-left: 10px"
            size="mini"
            placeholder="时空"
            v-model="pictureModel.pictureStyleId"
          >
            <el-option label="全部" :value="'0'"></el-option>
            <el-option
              v-for="(option, oi) in list1"
              :key="oi"
              :label="option.dictLabel"
              :value="option.dictValue"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div slot="footer">
        <el-button @click="updatePicture">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      accessToken: localStorage.getItem("access_token"),
      updateImageDialog: false,
      updateImageItem: null,
      afterUpdateImageURL: null,
      pictureIndex: 0,
      pictureModel: {
        content: "",
        scenarioId: "0",
        pictureAgeId: "0",
        picturePath: "",
        pictureSexId: "0",
        pictureStyleId: "0",
        pictureTypeId: "0",
      },
      model: {
        content: "",
        pictureTypeId: "0",
        scenarioId: "0",
        pictureSexId: "0",
        pictureAgeId: "0",
        pictureStyleId: "0",
        isAudit: "0",
        page: 1,
        limit: 20,
      },
      modifyModel: {
        name: "",
      },
      total: 0,
      stateList: [
        {
          id: "0",
          name: "审核状态",
        },
        {
          id: "1",
          name: "草稿箱",
          color: "rgba(215, 213, 223, 1)",
        },
        {
          id: "2",
          name: "已审核",
          color: "rgba(139, 209, 203, 1)",
        },
        {
          id: "3",
          name: "未通过",
          color: "rgba(248, 205, 227, 1)",
        },
      ],
      pictureList: [],
    };
  },
  created() {
    this.searchPicture();
  },
  watch: {
    model: {
      handler() {
        this.searchPicture();
      },
      deep: true,
    },
  },
  computed: {
    scenarioList() {
      return this.$store.state.scenarioList;
    },
    list1() {
      return this.$store.state.dict.list1;
    },
    list2() {
      return this.$store.state.dict.list2;
    },
    list3() {
      return this.$store.state.dict.list3;
    },
    list4() {
      return this.$store.state.dict.list4;
    },
  },
  methods: {
    returnState(state) {
      let re = {};
      this.stateList.forEach((res) => {
        if (res.id == state) {
          re = {
            name: res.name,
            color: res.color,
          };
        }
      });
      return re;
    },
    pictureTypeName(id) {
      let re = "";
      this.list4.forEach((element) => {
        if (element.dictValue == id) {
          re = element.dictLabel;
        }
      });
      return re;
    },
    beforeUpload(file) {
      const isPNG = file.type === "image/png";
      const isJPG = file.type === "image/jpeg";
      const isGIF = file.type === "image/gif";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!(isPNG || isJPG || isGIF)) {
        this.$message.error("上传图片只能是 PNG或JPG, GIF格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      return (isPNG || isJPG || isGIF) && isLt2M;
    },
    uploadSuccess(e) {
      this.afterUpdateImageURL = e.url;
      // 上传成功后刷新列表
      // this.updateImageDialog = false;
      // this.searchPicture();
    },
    hoverItem(item, state) {
      this.$set(item, "mask", state);
    },
    async updatePicture() {
      const {pictureModel} = this
      if(!pictureModel.scenarioId) {
        return this.$message.warning('请填写剧本')
      }
      if(this.afterUpdateImageURL) {
        pictureModel.picturePath = this.afterUpdateImageURL
      }
       await this.getRequest.updatePicture([pictureModel]);
      this.updateImageDialog = false;
      this.pictureList[this.pictureIndex] = pictureModel;
      
    },
    updateImage(v, i) {
      const item = JSON.parse(JSON.stringify(v));
      // 存入缓存
      for (let key in item) {
        typeof item[key] === "number" && (item[key] = item[key] + "");
      }
      this.afterUpdateImageURL = ''
      this.updateImageDialog = true;
      this.pictureIndex = i;
      this.pictureModel = this.updateImageItem = item;
    },
    async searchPicture() {
      const res = await this.getRequest.searchPicture(this.model);
      this.pictureList = res.data;
      this.total = res.count;
    },
  },
};
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 198px;
  height: 148px;
}
.avatar-uploader .el-upload:hover {
  border-color: #7f76bd;
}
.avatar {
  width: 198px;
  height: 148px;
  display: block;
}
</style>

<style lang='scss' scoped>
.scgl {
  width: 100%;
  height: 100%;
  .scgl-row1 {
    display: flex;
    justify-content: space-between;
    .right {
      display: flex;
    }
  }
  .scgl-row2 {
    margin-top: 10px;
    width: 100%;
    height: calc(100% - 57px);
    .scgl-row2-card {
      padding: 10px 0;
      height: 100%;
      overflow-y: scroll;
      .scgl-card-content {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: center;
        .scgl-item {
          margin-bottom: 40px;
          margin-right: 20px;
          width: 198px;
          .scgl-item-image {
            height: 148px;
            background: rgba(236, 234, 250, 1);
            border-radius: 4px;
            position: relative;
            .scgl-left {
              position: absolute;
              top: -5px;
              left: 0;
            }
            .right {
              position: absolute;
              top: 0;
              right: 0;
              height: 20px;
              width: 54px;
              font-size: 14px;
              color: #fff;
              text-align: center;
              line-height: 20px;
              border-radius: 4px;
            }
            .item-image-mask {
              position: absolute;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              background: rgb(0, 0, 0, 0.8);
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              border-radius: 4px;
              color: #fff;
            }
          }
          .item-message {
            font-size: 14px;
            .row1 {
              margin-top: 10px;
            }
            .row2 {
              margin-top: 10px;
              display: flex;
              justify-content: space-between;
            }
          }
        }
      }
    }
  }
  .row3 {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
}
</style>